<template>
  <div>
    <TableHeader
      :file-name="fileName"
      :items-csv="itemsCsv"
      :per-page="perPage"
      :page-options="pageOptions"
      :current-page="currentPage"
      :sort-by="sortBy"
      :sort-options="sortOptions"
      :filter="filter"
      :sort-desc="sortDesc"
      :show-statistics="true"
      :enable-export="true"
      @reset:filter="filter = ''"
      @update:current-page="(page) => getRows(perPage, page)"
      @update:per-page="(pp) => getRows(pp, currentPage)"
      @update:sort-by="(sb) => { sortBy = sb }"
      @update:sort-desc="(sd) => { sortDesc = sd }"
      @update:filter="(f) => { filter = f }"
    />
    <b-row>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="0"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="() => {currentPage = 1}"
        >
          <template #cell(image)="data">
            <b-img
              left
              class="mb-1 mb-sm-0"
              height="40"
              :src="data.value"
              alt="Left image')"
            />
          </template>

          <template #cell(user)="data">
            <b-row>
              <span>
                {{ data.value["FirstName"] }} ({{ data.value["email"] }})
              </span>
            </b-row>
          </template>
          <template #cell(action)="data">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item @click="showModalResearchAnswers(data)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Details</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showConfirmDeleteAnswer(data)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1">
        {{ currentPage !== Math.ceil(totalRows / perPage) ? perPage : totalRows % perPage }} - {{ totalRows }}
      </b-col>
      <b-col cols="11">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
          @input="(page) => getRows(perPage, page)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BDropdown, BDropdownItem, BImg, BRow, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TableHeader from '@/components/Genirecs/TableHeader.vue'

export default {
  /* global Backendless */

  components: {
    TableHeader,
    BTable,
    BRow,
    BCol,
    BImg,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fileName: '',
      itemsCsv: [],
      researchID: '',
      numberOfChoices: 2,
      titleEn: '',
      descriptionEn: '',
      currentChoices: [],
      perPage: 50,
      pageOptions: [10, 50, 75, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'research', label: 'Research' },
        { key: 'evaluation', label: 'Evaluation', sortable: true },
        { key: 'user', label: 'User', sortable: true },
        { key: 'created', label: 'Created', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        'action',
      ],
      items: [],
      AnswerArray: [{ num: 1 }, { num: 2 }],
      currentResearch: {},
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      choices: [
        { enKey: '', arKey: '' },
        { enKey: '', arKey: '' },
      ],
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    numberOfChoices() {
      this.choices = []
      this.AnswerArray = []
      for (let i = 0; i < this.numberOfChoices; i += 1) {
        this.choices.push({ enKey: '', arKey: '' })
        this.AnswerArray.push({ num: i + 1 })
      }
    },
  },
  mounted() {
    this.researchID = this.$route.params.researchId
    this.getRows()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY HH:mm:ss')
    },
    showModalResearchAnswers(entry) {
      console.log(this.researchID, entry.item.id, entry.item.user.objectId)
      this.$router.push({
        name: 'researches-answers-questions',
        params: {
          researchId: this.researchID,
          entryId: entry.item.id,
          userId: entry.item.user.objectId,
        },
      })
    },
    showConfirmDeleteAnswer(answer) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this Answer.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            Backendless.Data.of('ResearchAnswers')
              .remove({ objectId: answer.item.id })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Answer Deleted Successfully',
                    icon: 'BellIcon',
                    text: answer.item.titleEn,
                    variant: 'success',
                  },
                })
                this.getRows()
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
    },
    async getRows(pageSize = this.perPage, page = this.currentPage) {
      const offset = (page - 1) * pageSize
      this.fileName = `${this.$route.params.researchId}`
      const whereClause = `ResearchId = '${this.$route.params.researchId}'`
      const queryBuilder = Backendless.DataQueryBuilder.create()
      queryBuilder.setRelationsDepth(2)
      queryBuilder.setRelationsPageSize(100)
      queryBuilder.setPageSize(pageSize).setOffset(offset)
      queryBuilder.setWhereClause(whereClause)
      const query = Backendless.Data.of('ResearchAnswers')
      this.totalRows = await query.getObjectCount(queryBuilder)
      if (this.$route.params.researchId != null) {
        query
          .find(
            queryBuilder,
          )
          .then(researchAnswers => {
            this.currentResearch.answers = []
            const answers = []
            researchAnswers.forEach(async element => {
              if (element.dn !== 'SPAM ') {
                this.items.push({
                  id: element?.objectId,
                  research: element?.Research.localizations[0].title,
                  evaluation: element?.evaluation,
                  status: element?.status,
                  researchID: element?.ResearchID,
                  user: element.User,
                  created: this.formatDate(element?.created),
                })
                answers.push(
                  ...element?.Questions?.map(ans => ({
                    id: ans?.objectId,
                    answerTime: ans?.AnswerTime,
                    correctAnswerIndex: ans?.CorrectAnswer,
                    userChoiceValue: JSON.stringify(
                            ans?.questionId?.Choices[ans.UserChoice - 1],
                    ),
                    userChoice: ans?.UserChoice,
                    availableChoices: JSON.stringify(ans?.questionId?.Choices),
                    questions: ans?.questionId?.TitleEN,
                    description: ans?.questionId?.DescriptionEN,
                    audio: ans?.questionId?.Audio,
                    video: ans?.questionId?.Video,
                    image: ans?.questionId?.Image,
                    user: ans?.userId?.email,
                    created: this.formatDate(ans?.created),
                  })),
                )
              }
            })
            this.currentResearch.answers = researchAnswers
            this.itemsCsv = answers
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
