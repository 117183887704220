<template>
  <b-row>
    <b-col
      v-if="fileName"
      md="auto"
      sm="2"
      class="my-1"
    >
      <download-excel
        :data="itemsCsv"
        :name="fileName + `.xls`"
      >
        <b-button>Excel</b-button>
      </download-excel>
    </b-col>
    <b-col
      v-if="fileName"
      md="auto"
      sm="2"
      class="my-1"
    >
      <download-excel
        type="csv"
        :data="itemsCsv"
        :name="fileName + `.csv`"
      >
        <b-button>CSV</b-button>
      </download-excel>
    </b-col>
    <b-col
      v-if="showStatistics"
      md="auto"
      sm="2"
      class="my-1 mr-auto"
    >
      <router-link
        to="statistics"
        append
      >
        <b-button>Statistics</b-button>
      </router-link>
    </b-col>
    <slot />
    <b-col
      md="auto"
      sm="4"
      class="my-1 d-flex align-items-center"
    >
      <label
        class="d-inline-block text-sm-left mr-50 mb-0"
        for="perPageSelect"
      >Per page</label>
      <b-form-select
        id="perPageSelect"
        v-model="perPage"
        size="sm"
        :options="pageOptions"
        class="d-inline-block w-auto"
        @input="(pp) => $emit('update:per-page', pp)"
      />
    </b-col>
    <b-col
      md="auto"
      sm="8"
      class="my-1 d-flex align-items-center"
    >
      <b-form-group
        label="Sort"
        label-cols-sm="auto"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0 d-flex align-items-center"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            :value="sortBy"
            :options="sortOptions"
            class="w-75"
            @input="(sb) => $emit('update:sort-by', sb)"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            :value="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
            @input="(sd) => $emit('update:sort-desc', sd)"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      md="auto"
      class="my-1 d-flex align-items-center"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="auto"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0 d-flex align-items-center"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            type="search"
            :value="filter"
            placeholder="Type to Search"
            @input="(search)=> $emit('update:filter', search)"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="$emit('reset:filter')"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  props: {
    fileName: {
      type: String,
      default: '',
    },
    itemsCsv: {
      type: Array,
      default: () => [],
    },
    showStatistics: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 50,
    },
    pageOptions: {
      type: Array,
      default: () => [10, 50, 75, 100],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    sortBy: {
      type: String,
      default: '',
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: String,
      default: undefined,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    enableExport: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:per-page', 'update:current-page', 'reset:filter', 'update:sort-by', 'update:sort-desc', 'update:filter'],

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
